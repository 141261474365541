/**
 * Filename:  _portfolio.scss
 * Author:    Martin Dwyer
 * Purpose:   Provides styling for the portfolio component, noting
 *            that these styles change substantially whether it is
 *            a portfolio or a gallery
 * Date Mod:  December 8, 2021
 *
 */

.post-page {
    background-color: #f2f2f2;
    padding-bottom: 4rem;

    .post {
        .img-featured {
            @include media-breakpoint-down(md) {
                width: 70% !important;
                height: auto;
            }
        }
        .post-text {
            max-width: 1200px;

            .img-featured {
                display: block;
                margin: 0 auto;
                border-radius: 8px;
                box-shadow: 0 0 2rem rgba($black, 0.7);
            }

            h2,
            h3,
            h4,
            h5,
            h6 {
                font-weight: 600;
                margin-top: 2rem;
                margin-bottom: 0.75rem;
            }

            .post-title {
                font-size: 2.5rem;
                font-weight: 800;
                text-align: center;
                margin-top: 5rem;
                margin-bottom: 2rem;
            }

            p,
            li {
                font-size: 1.1rem;
                line-height: 1.5rem;
            }

            ol,
            ul,
            li {
                margin-bottom: 1rem;
            }

            #intro-text {
                margin: 0 auto;
                width: 85%;
                margin-bottom: 1rem;
                font-style: italic;

                a {
                    font-weight: 800;
                    text-underline-offset: 0.25rem;
                }
            }
        }
    }
}

#post-1 {
    height: auto;
    .photo-row {
        margin-bottom: 2rem;
        height: 350px;

        > div {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        img {
            margin-top: 2rem;
            border-radius: 0.5rem;
            box-shadow: 0.5rem 0.5rem 0.5rem 0.5rem rgba($black, 0.7);

            @include media-breakpoint-down(md) {
                display: block;
                margin: 0 auto;
                width: 70%;
                height: auto;
            }
        }

        @include media-breakpoint-down(md) {
            > div:nth-child(2) {
                display: none;
            }
        }
    }
}

#post-757 {
    > div.container > img {
        margin-bottom: 1rem;
        margin-right: 1rem;
        float: left;
        border-radius: 0.3rem;

        box-shadow: 0.5rem 0.5rem 0.5rem rgba($black, 0.7);

        transition: 2s;

        &:hover {
            transform: scale(2);
        }
    }

    h5 {
        margin-top: 3rem;
        margin-bottom: 2rem;
        clear: both;
    }
}

#post-1299 {
    table {
        margin-left: 8%;
    }
}

#post-883 {
    img {
        display: block;
        margin: 2rem auto;
        width: 40%;
        height: auto;
    }

    .body-type-images {
        width: 90%;
        margin: 0;
        margin-bottom: 2rem;
        padding: 0;
        display: flex;
        border-radius: 0.2rem;
        box-shadow: 0 0 2rem rgba($black, 0.7);

        @include media-breakpoint-down(md) {
            width: 150px;
            height: auto;
            margin-bottom: 0;
        }
    }

    .row > .col-md-2 {
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        @include media-breakpoint-down(md) {
            justify-content: flex-start;
            margin-left: 4rem;
        }
    }

    .row > .col-md-10 {
        @include media-breakpoint-down(md) {
            ul {
                padding-bottom: 1rem !important;
            }
        }
    }

    table {
        margin-top: 2rem;
        margin-bottom: 2rem;
    }

    #training-table {
        tr {
            padding-top: 1rem;
        }
    }
}

#post-808 {
    img {
        max-width: 40%;
        height: auto;
    }

    #post-808 > div.container > p:nth-child(22) > a > img {
        float: left;

        margin-right: 1rem;
        margin-bottom: 1rem;
    }
}

#post-808 > div.container > p:nth-child(22) > a > img {
    float: left;
    margin-right: 1rem;
    margin-bottom: 1rem;
}

#post-1120 > div.container > a > img {
    max-width: 60%;
    height: auto;
    display: block;
    margin: 1rem auto;
}

#post-1120 > div.container > p:nth-child(6) > img,
#post-1120 > div.container > p:nth-child(3) > img {
    float: left;
    margin-right: 1rem;
    margin-bottom: 1rem;
}

#post-310 > div.container > p:nth-child(6) > a > img {
    width: 40%;
    height: auto;
    display: block;
    margin: 2rem auto;
}
