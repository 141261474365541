.btn-rounded {
  @include media-breakpoint-down(sm) {
    display: none;
  }

  position: relative;
  padding: 0.9375rem 4.375rem 0.9375rem 1.25rem;
  font-weight: 700;
  margin: 0;

  background-color: $white;

  span {
    position: absolute;
    background-color: $primary;
    width: 2.5rem;
    height: 2.5rem;
    top: 50%;
    right: 5px;
    transform: translateY(-50%);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    i {
      color: $white;
      font-size: 1rem;
      line-height: 2.5rem;
    }
  }

  &:hover {
    @include gradient;
    color: $white;
    cursor: pointer;

    span {
      background-color: rgba($black, 0.6);
    }
  }
}
