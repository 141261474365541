/**
 *
 * Filename:  _services.scss
 * Author:    Martin Dwyer
 * Purpose:   Provides styling for the Services component
 * Date Mod:  December 8, 2021
 *
 */

.articles {
    background-color: #f2f2f2;

    padding-top: 8rem;
}

#services-intro-overview {
    padding-bottom: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;

    p {
        font-size: 1.15rem;

        line-height: 2.2rem;
        width: 80%;
        max-width: 800px;
        margin: 3rem auto;

        border-radius: 12px;
    }

    a {
        color: $secondary;
        text-decoration: none;
    }
}

.display-3--title.small-screen-title {
    margin-top: 2rem;
    margin-bottom: 2rem;
    width: 100%;
    text-align: center;

    @include media-breakpoint-down(lg) {
        font-size: 200%;
        display: block;
    }

    @include media-breakpoint-up(lg) {
        display: none;
    }
}

.display-3--title.full-screen-title {
    margin-top: 2rem;
    margin-bottom: 2rem;
    width: 100%;
    text-align: center;

    @include media-breakpoint-down(lg) {
        font-size: 150%;
        display: none;
    }

    @include media-breakpoint-up(lg) {
        display: block;
    }
}

.services {
    background: linear-gradient(
        rgba($white, 0) 0%,
        rgba($white, 0.2) 20%,
        rgba($white, 0.4) 40%,
        rgba($white, 0.6) 60%,
        rgba($white, 0.8) 80%,
        rgba($white, 1) 100%
    );

    position: relative;
    padding: 1rem 0;
    padding-top: 15vh;
    padding-bottom: 3rem;

    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS',
        sans-serif;

    h1.display-3 {
        color: $primary;
    }

    #services-intro-overview {
        padding-bottom: 3rem;
        display: flex;
        align-items: center;
        justify-content: center;

        p {
            color: #fff;
            font-size: 1.25rem;

            line-height: 2.2rem;
            display: block;
            width: 80%;
            max-width: 800px;
            margin: 3rem auto;
            background-color: rgba(0, 0, 0, 0.5);
            padding: 2rem;
            border-radius: 12px;
        }

        a {
            color: $secondary;
            text-decoration: none;
        }
    }

    .service-item-row {
        margin-top: 3rem;
        margin-bottom: 3rem;
        background-color: rgba($white, 0.5);

        padding: 3rem 1.5rem 3rem 1.5rem;

        background-color: rgba($white, 0.8);
        border: 1px solid rgba($white, 0.8);
        border-radius: 8px;
        box-shadow: 0px 0px 3.5rem rgba($white, 0.7);

        animation: fadeIn;
        animation-delay: 0s !important;
        animation-duration: 3.5s !important;

        .read-more {
            display: none;
        }
    }

    @keyframes loadIn {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }

    .services-column {
        margin: 0rem auto;

        .display-3--title {
            margin-top: 2rem;
            margin-bottom: 2rem;
            width: 100%;
            text-align: center;

            @include media-breakpoint-down(md) {
                font-size: 150%;
            }
        }

        &.right-content {
            display: none;

            @include media-breakpoint-up(md) {
                display: flex;
            }
        }

        &.left-content {
            @include media-breakpoint-up(md) {
                display: none;
            }
        }
    }

    &__content {
        padding: 1rem;
        margin-top: 0rem;

        height: auto;

        p {
            margin-top: 1rem;
            text-align: left;
            font-size: 1.2rem;
            line-height: 1.7rem;
        }

        .learn-btn {
            display: flex;
            justify-content: center;

            a {
                margin: 2rem auto;
                text-decoration: none;
                border: none;
                color: $primary;

                .rounded-pill {
                    background-color: $primary;
                    color: $white;
                    transition: 1s;

                    &:hover {
                        transform: scale(1.2);
                        box-shadow: 0 0 2rem rgba($secondary, 0.8);
                        background: linear-gradient(
                            147deg,
                            $primary-dark 0%,
                            $primary 55%,
                            $primary-light 70%,
                            $secondary 100%
                        );
                        color: #fff;

                        i {
                            color: $white;
                        }
                    }

                    span {
                        background-color: rgba($white, 0.1);
                        padding: 2rem;
                        i {
                            font-size: 2rem !important;
                            color: $white;

                            @include media-breakpoint-up(md) {
                                font-size: 3rem;
                            }
                        }
                    }
                }
            }
        }
    }

    &__pic {
        margin-top: 5rem;
        display: flex;
        justify-content: center;

        img {
            height: auto !important;
            border-radius: 8px;
            box-shadow: 0rem 0rem 1rem 1rem rgba($black, 0.3);
            transition: 1s;

            &:hover {
                transform: scale(1.1);
                box-shadow: 0rem 0rem 1rem 1rem rgba($black, 0.6);
            }

            @include media-breakpoint-down(lg) {
                margin-bottom: 1rem;
            }
        }

        @media (max-width: 992px) {
            margin-top: 0rem;
        }
    }
}

.blog .ast-container .content-area.primary::after {
    background: $white;
}

.blog {
    &.ast-separate-container {
        background-color: $white;
    }

    .ast-row::before {
        content: 'Blog Posts';
        display: block;
        width: 100%;
        text-align: center;
        font-size: 3rem;
        font-weight: 600;
        color: $primary;
        background-color: $white;
        padding: 2rem;
        margin: 0 auto;
        font-family: Josefin Sans;
    }
}

article.post {
    .ast-blog-featured-section.post-thumb {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;

        .post-thumb-img-content.post-thumb {
            width: 50%;

            img {
                width: 100%;
                height: auto;
                border-radius: 8px;
                box-shadow: 2rem 2rem 1rem rgba(0, 0, 0, 0.7);
                margin-bottom: 3rem;
            }
        }
    }

    .entry-header {
        h2 {
            a {
                text-decoration: none;
                border-bottom: none;
            }
        }
    }

    .entry-meta {
        color: black;

        a {
            color: blue;
        }
    }

    .read-more {
        a {
            text-decoration: none;
            color: blue;
            padding-bottom: 3px;
            border-bottom: 1px solid blue;

            &:hover {
                font-weight: 700;
            }
        }
    }
}

#post-video-holder {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    #post-video {
        width: 1000px;
        height: 500px;

        @include media-breakpoint-down(lg) {
            width: 800px;
            height: 400px;
        }

        @include media-breakpoint-down(md) {
            width: 300px;
            height: 150px;
        }
    }
}
