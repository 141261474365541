/**
 *
 * Filename:  _services.scss
 * Author:    Martin Dwyer
 * Purpose:   Provides styling for the Services component
 * Date Mod:  December 8, 2021
 *
 */

.page {
    background: #f2f2f2;
}

.about-intro-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h1 {
        font-size: 2rem;
    }
}

.about-martin-image {
    padding: 0;
    width: 200px;
    height: auto;
    border-radius: 8px;
    margin-bottom: 2rem;
    box-shadow: 0px 0px 1.5rem rgba(0, 0, 0, 0.7);
}

#about-page {
    background: #f2f2f2;

    position: relative;
    padding: 1rem 0;
    padding-top: 15vh;
    padding-bottom: 3rem;

    & > div > div.container > div.row {
        padding: 3rem 0rem 3rem 2rem;
        border-radius: 8px;
    }

    h2 {
        color: $secondary;
        font-size: 2.25rem;
        font-weight: 700;
        margin-bottom: 1.5rem;
        text-shadow: 0.1rem 0.1rem 0.25rem rgba(0, 0, 0, 0.7);
    }

    .about-images-profile {
        width: 150px;
        height: auto;
        margin-top: 0.7rem;
        margin-right: 1.5rem;
        margin-bottom: 0.5rem;
        border-radius: 8px;
        box-shadow: 0.2rem 0.2rem 0.2rem 0.2rem rgba($black, 0.3);
        float: left;

        @include media-breakpoint-down(md) {
            width: 85px;
            margin-right: 0.7rem;
            margin-bottom: 0.7rem;
        }
    }

    .about-images {
        padding-top: 4rem;
    }

    .about-images-right {
        width: 25%;
        height: auto;
        float: right;
        margin-top: 0.7rem;
        margin-left: 0.7rem;
        margin-bottom: 0.7rem;
        border-radius: 8px;
        box-shadow: 0.2rem 0.2rem 0.2rem 0.2rem rgba($black, 0.3);

        &.big40 {
            width: 40%;
        }
    }

    .about p {
        font-size: 1.2rem;
    }

    ul li {
        font-size: 1.1rem;
    }
}

#post-2 > div > div > div {
    margin-left: 0;
    margin-right: 0;
}

#post-2 {
    .entry-header {
        .post-thumb {
            img {
                width: 50% !important;
                margin-bottom: 3rem;
                border-radius: 8px;
                transform: scale(0.9) rotate(-5deg);
                box-shadow: 2rem 2rem 2rem rgba(0, 0, 0, 0.7);
                transition: all 2s;

                &:hover {
                    transform: scale(1.2) rotate(0deg);
                    box-shadow: 2rem -2rem 2rem rgba(0, 0, 0, 0.7);
                }
            }
        }

        .entry-title {
            display: none;
        }
    }

    a {
        text-decoration: none !important;

        em {
            text-decoration: none;
        }
    }
}
