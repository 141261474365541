#privacy-page {
  max-width: 1320px;
  padding: 3rem;

  border-radius: 8px;

  #privacy-info {
    max-width: 1320px;
    margin: 2rem auto;

    h3 {
      font-weight: 700;
      font-size: 2.5rem;
      text-align: center;
      margin-bottom: 2rem;
    }

    p {
      font-size: 1rem;
      line-height: 1.5rem;
    }

    li {
      font-size: 1rem;
      line-height: 1.5rem;
      margin-left: -1rem;
      padding-left: 1rem;
      margin-bottom: 1rem;
    }
  }
}

.page-template {
  #post-214 {
    .entry-header {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .post-thumb {
        width: 50%;

        img {
          width: 100%;
          height: auto;
          box-shadow: 1rem 1rem 1rem rgba(0, 0, 0, 0.7);
          border-radius: 8px;
        }
      }

      .entry-title {
        display: none;
      }
    }
  }
}
