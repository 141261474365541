/**
 *
 * Filename:  _custom.scss
 * Author:    Martin Dwyer
 * Purpose:   Provides imports and customizes Bootstrap sass variables
 * Date Mod:  December 8, 2021
 *
 */

@import '../../../../node_modules/bootstrap/scss/bootstrap.scss';

// IMPORT BOOSTRAP ICONS
@import '../../../../node_modules/bootstrap-icons/font/bootstrap-icons.scss';

// Import animations
@import '../../../../node_modules/animate.css/animate.min.css';

// Theme Colors
// scss-docs-start gray-color-variables
$white: #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black: #000;
// scss-docs-end gray-color-variables

// fusv-disable
// scss-docs-start gray-colors-map
$grays: (
    '100': $gray-100,
    '200': $gray-200,
    '300': $gray-300,
    '400': $gray-400,
    '500': $gray-500,
    '600': $gray-600,
    '700': $gray-700,
    '800': $gray-800,
    '900': $gray-900,
);

// useful colors for use throughout
$indigo: #6610f2;
$red: #dc3545;
$orange: #fd7e14;
$yellow: #ffc107;
$green: #198754;
$teal: #20c997;
$cyan: #0dcaf0;

// scss-docs-end color-variables
$purple-dark: #9926f0;
$purple-light: #bb6ef5;
$pink: #d122e3;
$bytes-orange: #ff6a00;
$image-blue: $green;
$image-light-blue: #8ea3ff;
$plum: #42275a;
$plum-light: #9a6592;
$mauve-dark: #42275a;
$mauve-light: #734b6d;
$web-dev-primary: #df822f;
$web-dev-primary-light: #eaab95;
$web-dev-secondary: #706695;
$penguin-primary: #2b5075;
$penguin-primary-light: #4878b4;
$penguin-primary-dark: #0f1a28;
$penguin-secondary: #f06418;

$wildthings-primary: #553c20;
$wildthings-primary-light: #b98346;
$wildthings-primary-dark: #251a0e;
$wildthings-secondary: #80b55f;

// Setting the primary and secondary colors for Bootstrap
$primary: #006600;
$primary-light: #00b300;
$primary-dark: #003300;
$secondary: #cc6600;

// Nav and Footer Background settings
$nav-background: rgba(#262626, 0.9);
$footer-background: rgba(#262626, 0.9);

// Accordion overrides

// Changing Accordion icon colors
$accordion-icon-color: $primary;
$accordion-icon-active-color: $secondary;

// Changing Accordion icon width and padding
$accordion-icon-width: 1.875rem;
$accordion-button-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$accordion-icon-color}'><path fill-rule='evenodd' d='M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z'/><path d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z'/></svg>") !default;
$accordion-button-active-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$accordion-icon-active-color}'><path d='M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z'/><path d='M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z'/></svg>");
$accordion-padding-y: 1.5rem;
$accordion-padding-x: 1.5rem;

// setting theme-color-variables
$success: $green;
$info: $cyan;
$warning: $yellow;
$danger: $red;
$light: $gray-100;
$dark: $gray-900;

.btn-primary {
    background-color: $primary;
    border-color: $primary;
    color: $white;

    box-shadow: 0 0 1rem rgba($white, 0.8);

    transition: 1s;

    &:hover {
        transform: scale(1.1);
        box-shadow: 0 0 2rem rgba($white, 0.8);
        background-color: $primary;
        border-color: $primary;
        color: $white;
    }
}

// IMPORT BOOTSTRAP 5
@import '../../../../node_modules/bootstrap/scss/bootstrap.scss';

// IMPORT BOOSTRAP ICONS
@import '../../../../node_modules/bootstrap-icons/font/bootstrap-icons.scss';

// Import animations
@import '../../../../node_modules/animate.css/animate.min.css';

// IMPORT FONT AWESOME ICONS
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.2/css/all.min.css');

// Import font families
@import url('https://fonts.googleapis.com/css2?family=Acme&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Aclonica&display=swap');
