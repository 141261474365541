/**
 * Filename:  _intro-section.scss
 * Author:    Martin Dwyer
 * Purpose:   Provides styling for the LandingPage component
 * Date Mod:  December 8, 2021
 *
 */

.jumbotron {
    background-color: rgba($black, 0.5);
    padding-top: 5rem;
    min-height: calc(100vh - 0vh);

    .right,
    .left {
        padding-top: 4rem;
        padding-bottom: 4rem;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .left {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include media-breakpoint-down(lg) {
            padding-bottom: 0;
        }

        .site-title {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            h1 {
                color: $light;
                font-size: 5rem;
                font-weight: 700;
                text-shadow: 0rem 0rem 1rem $black;
                animation-name: zoomInDown;
                animation-duration: 1s;
                animation-iteration-count: 1;

                @include media-breakpoint-down(xl) {
                    font-size: 4rem;
                    font-weight: 600;
                    margin-bottom: 3rem;
                }

                @include media-breakpoint-down(lg) {
                    font-size: 3rem;
                }
            }

            p {
                max-width: 500px;
                .pre,
                .post {
                    color: $warning;
                    font-weight: 400;
                    font-size: 80%;
                }

                color: $light;
                text-shadow: 0rem 0rem 1rem $black;
                font-size: 1.25rem;
                margin-bottom: 2rem;

                @include media-breakpoint-down(xl) {
                    max-width: 350px;
                    font-size: 1rem;
                }

                @include media-breakpoint-down(lg) {
                    max-width: 300px;
                    margin-bottom: 0;
                }
            }

            .tagline {
                font-weight: 500;
                font-size: 1.25rem;
                text-shadow: 0rem 0rem 1rem $black;
                animation-name: zoomInDown;
                animation-duration: 1s;
                animation-iteration-count: 1;
            }
        }

        .site-description {
            animation-name: zoomInDown;
            animation-duration: 1s;
            animation-iteration-count: 1;
            color: $light;

            h3,
            h6 {
                text-shadow: 0rem 0rem 1rem $black;
            }

            p {
                width: 80%;
                max-width: 500px;
                text-align: center;
                margin: 0 auto;
                font-size: 1.25rem;
                font-weight: 500;
                text-shadow: 0rem 0rem 1rem $black;

                @include media-breakpoint-down(lg) {
                    width: 90%;
                    font-size: 1rem;
                }
            }

            ul {
                margin-top: 2.5rem;
            }

            li {
                font-size: 1.25rem;

                i {
                    color: #006600;
                    height: 100%;
                    padding: 0;

                    background-color: #fff;
                    line-height: 0.75rem;
                    border-radius: 0.1rem;
                }
            }
        }

        .btn {
            margin-top: 4rem;
            margin-bottom: 10vh;
            background-color: $primary;
            border-color: $primary;
            color: $light;
            animation-name: zoomInDown;
            animation-duration: 1s;
            animation-iteration-count: 1;

            @include media-breakpoint-down(lg) {
                display: none;
            }
        }
    }

    .right {
        @include media-breakpoint-down(lg) {
            padding-top: 0;
        }

        .featured-image {
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 80%;
                height: auto;
                border-radius: $border-radius;
                margin-top: 1rem;
                margin-bottom: 1rem;
                animation-name: zoomInDown;
                animation-duration: 1s;
                animation-iteration-count: 1;

                @include media-breakpoint-down(lg) {
                    width: 80%;
                }
            }
        }

        .site-description {
            width: 100%;
            animation-name: zoomInDown;
            animation-duration: 1s;
            animation-iteration-count: 1;
            color: $light;

            p {
                width: 80%;
                text-align: center;
                margin: 0 auto;
                font-size: 1.25rem;
                font-weight: 500;
                text-shadow: 0rem 0rem 1rem $black;

                @include media-breakpoint-down(lg) {
                    width: 90%;
                    font-size: 1rem;
                }
            }
        }
    }
}
article.post-1535 {
    color: #fff;
    min-height: 90vh;
}

article.post-1535 .display-2 .display-2--intro {
    color: #fff;
}

#home > div > div > div.col-lg-6.intros.photo {
    display: flex;
    align-items: center;
    justify-content: center;
}

/* featured posts */

div.featured-posts {
    padding: 3rem 3rem 3rem 3rem;
    background-color: #fff;

    @include media-breakpoint-down(lg) {
        padding-top: 1rem;
    }

    h2 {
        margin-bottom: 2rem;
        color: #666;
        text-shadow: 0.1rem 0.1rem 0.1rem rgba($black, 0.7);
        font-size: 2.5rem;
    }

    div.card {
        border-color: transparent;
        border-right: 1px solid rgba($black, 0.1);
        margin-bottom: 2rem;

        .card-title {
            margin-bottom: 1.5rem;
            font-size: 2rem;

            @include media-breakpoint-down(xl) {
                font-size: 1.5rem;
            }

            a {
                color: $dark;
                text-decoration: none;
            }
        }

        .card-img-holder {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                height: auto;
                width: 90%;
                margin: 2rem auto;
                border-radius: 0.2rem;
                box-shadow: 0 0 2rem rgba($black, 0.7);
                transform: scale(0.9);
                transition: 1s;

                &:hover {
                    transform: scale(1.2);
                }
            }
        }

        .card-body {
            padding-right: 0;
            padding-left: 0;
        }

        .card-text {
            font-size: 1.1rem;
            line-height: 1.6rem;
        }
    }
}

#featured-posts div:nth-child(3) {
    border-right: none;
}

.carousel-inner {
    height: 60vh;
}

.carousel.slide {
    padding: 0;
    height: 60vh;

    @include media-breakpoint-down(lg) {
        display: none;
    }

    h3 {
        font-size: 5rem;
        text-shadow: 0.2rem 0.2rem 0.5rem $black;
    }

    .btn {
        font-size: 1rem;
        padding: 0.5rem 1.25rem;

        background-color: $primary;
        border-color: $primary;
        box-shadow: 0 0 2rem white;
        color: $white;
        transition: 1s;

        &:hover {
        }
    }

    .btn-rounded {
        display: block;
        margin: 0 auto;
        width: 12rem;
        border: none;
        background-color: rgba(255, 255, 255, 0.65);
        box-shadow: 0 0 2rem white;
        color: #553c20;

        &:hover {
            box-shadow: 0 0 2rem #80b55f;
            color: #fff;
        }
    }

    .carousel-caption {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}

.carousel-item img {
    height: 60vh;
    width: auto;
    overflow: hidden;
}

.our-approach {
    padding: 5rem 2rem 4rem 2rem;
    background-color: $white;
    h2 {
        text-align: center;
        color: #666;
        font-size: 3rem;
        text-shadow: 0.1rem 0.1rem 0.1rem #666;
        margin-bottom: 2rem;
    }

    .card {
        border-color: transparent;
    }
}

.about-martin {
    padding: 3rem 2rem 6rem 2rem;
    background-color: $white;

    @include media-breakpoint-down(lg) {
        padding-top: 0rem;
        padding-left: 0rem;
        padding-right: 0rem;
    }

    .row {
        padding: 0;
    }

    h2 {
        text-align: center;
        color: #666;
        font-size: 3rem;
        text-shadow: 0.1rem 0.1rem 0.1rem #666;
        margin-bottom: 2rem;
    }

    .card {
        border-color: transparent;
        padding-bottom: 4rem;
        border-bottom: 1px solid rgba(#000, 0.1);

        @include media-breakpoint-up(lg) {
            padding-bottom: 1rem;
            border-bottom: none;
        }
    }

    .approach.about {
        border-right: 1px solid rgba(#000, 0.1);

        @include media-breakpoint-down(lg) {
            border-right: none;
        }

        h3 {
            color: $secondary;
            text-shadow: 0.1rem 0.1rem rgba(#000, 0.6);
            text-align: center;
            margin-bottom: 2rem;
        }

        ul {
            padding-right: 2rem;
        }

        p,
        li {
            font-size: 1.1rem;
        }

        li i {
            color: #006600;
        }

        li {
            padding-bottom: 0.5rem;
            margin-left: 2rem;

            @include media-breakpoint-down(lg) {
                margin-left: 0;
            }
        }

        img {
            float: left;
            margin-top: 0rem;
            margin-right: 1rem;
            margin-bottom: 1rem;
            border-radius: 8px;
            box-shadow: 0.2rem 0.2rem 0.2rem 0.2rem rgba($black, 0.3);
        }
        .transformation-photo {
            float: right;
            width: fit-content;

            width: 80%;
            height: auto;
            margin-top: 0rem;
            margin-left: 1rem;
            margin-bottom: 1rem;
            border-radius: 8px;
            box-shadow: 0.2rem 0.2rem 0.2rem 0.2rem rgba($black, 0.3);

            @include media-breakpoint-down(md) {
                float: none;
                display: block;
                margin: 1rem auto;
                width: 50%;
            }
        }

        .featured-image {
            clear: both;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 2rem 0;

            img {
                width: 80%;
                height: auto;
            }
        }
    }

    .approach.battle {
        padding-top: 4rem;

        @include media-breakpoint-down(lg) {
            padding-left: 0rem;
            padding-right: 0rem;
        }
        @include media-breakpoint-up(lg) {
            padding-top: 0rem;
        }

        h3 {
            color: $secondary;
            text-shadow: 0.1rem 0.1rem rgba(#000, 0.6);
            text-align: center;
        }

        .table-wrapper {
            height: 100%;
            margin: 2rem 0;
            background: none;
            background-image: url('../images/metS-background.png');
            background-repeat: no-repeat;
            background-position: center top;
            background-size: contain;

            .metabolic-solution {
                height: 100%;
                background: rgba($white, 0.8);

                ul {
                    margin: 0rem 10%;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;

                    li {
                        margin-left: 10%;
                        margin-bottom: 3rem;
                        padding-left: 5%;
                        font-size: 1.5rem;
                        font-weight: 500;
                        color: $primary;
                        text-shadow: 0.05rem 0.05rem 0.05rem rgba(#000, 0.9);

                        i {
                            font-size: 1rem;
                            color: $primary;
                            text-shadow: none;
                        }
                    }
                }
            }
        }

        table {
            margin: 0;
            tr {
                height: 5rem;
                border-color: transparent;
                padding-bottom: 1rem;
            }

            th {
                color: #006600;
                text-align: right;
                margin-right: 0.5rem;
                padding-right: 2rem;
            }

            th,
            td {
                padding-bottom: 1rem;
                background-color: rgba($white, 0.75);
            }
        }
    }

    .approach.individual {
        border-right: 1px solid rgba(#000, 0.1);

        @include media-breakpoint-down(lg) {
            border-right: none;
        }

        h3 {
            color: $secondary;
            text-shadow: 0.1rem 0.1rem rgba(#000, 0.6);
            text-align: center;
        }
    }

    .image-card {
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: visible;

        img {
            width: 95%;
            border-radius: $border-radius;
            border-color: rgba($black, 0.7);
            border: 1px solid rgba($black, 0.7);
            box-shadow: 1rem 1rem 0.5rem rgba($black, 0.7);
            animation-name: zoomInDown;
            animation-duration: 1s;
            animation-iteration-count: 1;

            &.left {
                transform: scale(0.95);
                transition: 1s;

                &:hover {
                    transform: scale(1.05);
                }
            }

            &.right {
                transform: scale(0.95);
                transition: 1s;

                &:hover {
                    transform: scale(1.05);
                }
            }
        }
    }
}

.metabolic-syndrome {
    padding: 3rem 3rem 3rem 3rem;
    background-color: #fff;
    min-height: 100vh;

    h2 {
        margin-bottom: 2rem;
        color: #666;
        text-shadow: 0.1rem 0.1rem 0.1rem rgba($black, 0.7);
        font-size: 2.5rem;
    }

    .card {
        border: none;
        padding: 0;
        background: none;
        background-image: url('../images/metS-background.png');
        background-repeat: no-repeat;
        background-position: center top;
        background-size: contain;

        .metabolic-info-wrapper {
            margin: 0;
            padding: 0;
            height: 100%;
            background-color: rgba(#fff, 0.6);
        }
    }

    p {
        font-size: 1.25rem;

        u {
            text-underline-offset: 0.2rem;
            color: #006600;
            font-weight: 600;
        }
    }

    ul {
        padding-right: 10%;

        li {
            font-size: 1.15rem;
            margin-bottom: 0.75rem;
            color: #006600;

            ul {
                li {
                    font-size: 1rem;
                    color: #000;
                    margin-left: 2rem;
                    margin-bottom: 0.25rem;

                    @include media-breakpoint-down(md) {
                        margin-left: 0;
                    }
                }
            }
        }
    }

    #front-page-video-holder {
        width: 100%;
        height: 100%;
        padding-top: 3rem;
        padding-bottom: 2rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;

        #front-page-video {
            width: 700px;
            height: 350px;
            max-width: 100%;

            @include media-breakpoint-down(xl) {
                width: 600px;
                height: 300px;
            }

            @include media-breakpoint-down(md) {
                width: 500px;
                height: 250px;
            }
        }
    }

    .image-card {
        border-left: 0.1rem solid lightgray;
        padding-left: 2rem;

        @include media-breakpoint-down(lg) {
            border: none;
            padding-left: 0;
        }

        h3 {
            color: #006600;
            margin-bottom: 2rem;
            font-weight: 600;
        }
    }

    .btn-learn-more {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 3rem;

        .btn {
            background-color: #006600;
            border-color: #006600;
            transition: ease-in-out;
            transition-duration: 1s;

            &:hover {
                transform: scale(1.1);
                box-shadow: 0 0 3rem #006600;
            }
        }
    }
}
